import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import AssActions from '../../actions/assessments';
import { MessageBox, Label, PanelPlaceholder, Checkbox } from '../../kado/components';
import I18, { i18Get } from '../../i18';
import { datetime } from '../../kado/utils';
import '../../kado/css';
import SpinnerAnt from '../../kado/components/spinner-ant';
import { Table,Radio } from 'antd';
import { Options } from './ass-components';
import { getTrans, apiHeaders } from '../../kado/utils';
import './likertScale.css';
import Likertoptions from './ass-components/likertoptions';

class LikertScale extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    getAnswerForQuestion(questionId) {
      var answers = this.props.answers;
      var answerForQuestion = {};
      if(answers && answers.length > 0) {
          answers.map(answer => {
              if(answer.question === questionId){
                  answerForQuestion = answer;
              }
          });
      }
      return answerForQuestion;
  }

  getSubQuestions(question) {
      var subQstnArray = []
      if(question.ansType === 'o' || question.ansType === 'm') {
          const answer = this.getAnswerForQuestion(question.uuid)
          question.options.map((option) => {
              if(option.uuid ===  answer.option || (typeof answer.mOptions !== 'undefined' && answer.mOptions.indexOf(option.uuid) !== -1)) {
                  if(option.section) {
                      subQstnArray.push(<div style={{border: 'none'}} className="section_title" >{renderHTML(this.getSection(option.section))}</div>)
                      subQstnArray  = [...subQstnArray, ...this.renderQuestions(option.section)] 
                  }
              }
          })
      }
      return subQstnArray
  }

  renderQuestions(section) {
    var qstnsArray = []
    
    }
    optionColours(number){
        var colour = []
        switch (number) {
            case 2 : colour[0] ='rgb(255,158,195)'
                     colour[1] = 'rgb(185,222,255)'
                     break;
            case 3:  colour[0] ='rgb(255,158,195)'
                     colour[1] = 'rgb(255,251,156)'
                     colour[2] = 'rgb(185,222,255)'
                     break;
            case 4: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,205,176)'
                    colour[2] = 'rgb(220,237,230)'
                    colour[3] = 'rgb(185,222,255)'
                    break;
            case 5: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,205,176)'
                    colour[2] = 'rgb(255,251,156)'
                    colour[3] = 'rgb(220,237,230)'
                    colour[4] = 'rgb(185,222,255)'
                    break;
            case 6: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,182,186)'
                    colour[2] = 'rgb(225,228,166)'
                    colour[3] = 'rgb(238,244,212)'
                    colour[4] = 'rgb(203,230,249)'
                    colour[5] = 'rgb(185,222,255)'
                    break;
            case 7: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,182,186)'
                    colour[2] = 'rgb(255,228,166)'
                    colour[3] = 'rgb(255,251,156)'
                    colour[4] = 'rgb(238,244,212)'
                    colour[5] = 'rgb(203,230,249)'
                    colour[6] = 'rgb(185,222,255)'
                    break;
            case 8: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,182,186)'
                    colour[2] = 'rgb(255,228,166)'
                    colour[3] = 'rgb(255,240,161)'
                    colour[4] = 'rgb(247,248,184)'
                    colour[5] = 'rgb(255,251,156)'
                    colour[6] = 'rgb(238,244,212)'
                    colour[7] = 'rgb(185,222,255)'
                    break;
            case 9: colour[0] ='rgb(255,158,195)'
                    colour[1] = 'rgb(255,182,186)'
                    colour[2] = 'rgb(255,228,166)'
                    colour[3] = 'rgb(255,240,161)'
                    colour[4] = 'rgb(255,251,156)'
                    colour[5] = 'rgb(247,248,184)'
                    colour[6] = 'rgb(255,251,156)'
                    colour[7] = 'rgb(238,244,212)'
                    colour[8] = 'rgb(185,222,255)'
                    break;
            case 10: colour[0] ='rgb(255,158,195)'
                     colour[1] = 'rgb(255,182,186)'
                     colour[2] = 'rgb(255,228,166)'
                     colour[3] = 'rgb(255,240,161)'
                     colour[4] = 'rgb(255,246,159)'
                     colour[5] = 'rgb(251,250,170)'
                     colour[6] = 'rgb(247,248,184)'
                     colour[7] = 'rgb(255,251,156)'
                     colour[8] = 'rgb(238,244,212)'
                     colour[9] = 'rgb(185,222,255)'
                     break;
        }
        return colour
    }

  getSection(section){
    return getTrans(section,'title')
}

    render() {
      var Data = []
      if(this.props.questions) {
          this.state.questions = this.props.questions;  
      }
       this.state.questions.questions.map((section, index) => {
      
          var opt = [];
          var optcol = []
          if (section.section.section_type === 'l') {
              var colours = section.section.likertscaletype.colour_schema === true && this.optionColours(section.section.likertscaletype.options.length)
              var sortedOptions = _.orderBy(section.section.likertscaletype.options,'order','asc')
              sortedOptions.forEach((o,index) => {
                  opt.push(getTrans(o,'title'));
                  colours && colours.length !== 0 && optcol.push(colours[index])
              });
              
              if(section.section.likertscaletype.startwithblue) {
                optcol.reverse()
                }
              if(section.section.likertscaletype.not_applicable) {
                opt.push(section.section.likertscaletype.not_applicable_text !== null ? getTrans(section.section.likertscaletype,'not_applicable_text') : i18Get('Not Applicable'));
                if(section.section.likertscaletype.colour_schema) {
                      optcol.push('#FAF7F7');
                  }
              }

              Data = {
                  section_title :getTrans(section.section,'general_question_headline'),
                  option_title : opt,
                  color : optcol,
                  questions : section.section.questions

              }
          }

      })
      return (
        <div>
            
             { this.props.questions.section_type !== 'l' ?
                  
                      <div key={this.props.questions.uuid} className="section_box">
                          <div className="section_title">{renderHTML(this.getSection(this.props.questions))}</div>
                          <div className="section_question">{this.renderQuestions(this.props.questions)}</div>
                      </div>
               
                      :
             
                      <div key={this.props.questions.uuid} className="section_box likert-section">
                          <div className='likert-table-div'>
                          <table className='table likert-sec-table'>
                              <thead>
                                  <tr>
                                      <th scope="col">{Data.section_title !== null ? Data.section_title:''}</th>
                                          {Data.option_title.map((option, ind) => (
                                              <th key={ind} scope="col" style={{backgroundColor: Data.color[ind],textAlign:'center'}}>{option}</th>
                                          ))}
                                  </tr>
                              
                              </thead>
                              <tbody>
                                  {this.props.questions.questions.map((question,index) => (
                                          
                                          <Likertoptions key={question.uuid} question={question} 
                                            assessment={this.props.assessment} 
                                            likertType = {this.props.questions}
                                            qstnr={this.props.questions.questionnaire}
                                            questionnaire={this.props.questions.questionnaire}
                                            answer={this.getAnswerForQuestion(question.uuid)}
                                            documented={this.props.documented}
                                            index = {index}
                                          />
                                      )

                                  )}
                              
                              </tbody>
                           </table>
                           </div>
                    </div>
             }
        </div>
      )
  }
  }
export default connect(state => ({
    assessments: state.assessments,
    translations: state.translations,
}))(LikertScale);
